import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Collapse from '@mui/material/Collapse';
import Backdrop from '@mui/material/Backdrop';


TransitionAlerts.propTypes = {
  Messenge: PropTypes.string,
};

export default function TransitionAlerts({ Messenge, ...other }) {

  const [open, setOpen] = useState(false);


  useEffect(() => {

    setOpen(other.statusAlert);

  }, [other]);



  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} >

      <Collapse in={open}>
        <Alert
          severity={other.severity}
          sx={{ mb: 2, }}
        >
          <AlertTitle>{Messenge}</AlertTitle>
        </Alert>
      </Collapse>

    </Backdrop>
  );
}