import React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';

export default function CoordManual() {
  return (
    <>
      <Typography variant="h6">No te preocupes!</Typography>
      <List disablePadding>
        <FormHelperText>Tenemos otra alternativa para que podamos ubicar tu local restaurante, solo sigue estos simples pasos:</FormHelperText>
      </List>
      <Grid container spacing={2}>
        <Grid item xs={9} sm={12}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Guia Manual.
          </Typography>
          <List disablePadding>
            <FormHelperText>
              Presiona el menú flotante <AddCircleIcon fontSize={'small'} />
            </FormHelperText>
            <FormHelperText>
              luego este icono <EditLocationIcon fontSize={'small'} />
            </FormHelperText>
          </List>
          <FormHelperText>
            Se mostrará un pequeño formulario donde podrá ingresar las coordenadas que obtuvo desde Google Maps.
          </FormHelperText>
        </Grid>
      </Grid>
    </>
  );
}
