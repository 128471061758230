const API_URL = process.env.REACT_APP_API_AUTH; // Conection Api
const url = process.env.REACT_APP_API_VERIFY; // Conection Api

export default async function requestNewAccessToken(repToken) {
  try {
    const response = await fetch(`${API_URL}${url}`, {
      method: 'POST',
      headers: {
        referrerPolicy: 'unsafe_url',
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://kartaqr.com',
        mode: 'same-origin',
      },
      body: JSON.stringify(repToken),
    });

    if (response.ok) {
      const json = await response.json();
      if (json.error) {
        throw new Error(json.error);
      } else {
        return json;
      }
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
  }
}
