import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import SpeedDial from '@mui/material/SpeedDial';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SaveIcon from '@mui/icons-material/Save';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';

CircularMenu.propTypes = {
  name: PropTypes.string,
};


const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: 'absolute',
  '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
    bottom: theme.spacing(10),
    right: theme.spacing(5),
  },
  '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
    top: theme.spacing(10),
    left: theme.spacing(5),
  },
}));


export default function CircularMenu({ name, ...other }) {

  const actions = [
    // { icon: <FileCopyIcon onClick={other.Copy} />, name: 'Tour' },
    { icon: <GpsFixedIcon onClick={other.reset} />, name: 'Reubicar', disabled: other.disabledreset },
    { icon: <PriorityHighIcon onClick={other.Copy} />, name: 'Tour', disabled: other.disabledreset },
    { icon: <EditLocationIcon onClick={other.handleCoordenate} />, name: 'Google Coordenadas', disabled: other.disabledreset },
    //  { icon: <SaveIcon onClick={other.Save} />, name: 'Guardar', disabled: other.disabledreset },
    // { icon: <PrintIcon onClick={other.Print} />, name: 'Print' },
    // { icon: <ShareIcon onClick={other.Share} />, name: 'Share' },
  ];


  return (
    <StyledSpeedDial
      ariaLabel="SpeedDial playground example"
      hidden={other.hidden}
      icon={<SpeedDialIcon />}
      direction={'up'}
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          disabled={action.disabled}
        />
      ))}
    </StyledSpeedDial>

  );
}
