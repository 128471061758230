import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

// ----------------------------------------------------------------------

TextFieldicon.propTypes = {
  name: PropTypes.string,
};

export default function TextFieldicon({ name, ...other }) {

  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (


        <TextField
          {...field}
          fullWidth
          disabled={other.DisabledButton}
          autoComplete="given-name"
          variant="filled"
          value={typeof field.value === 'number'
            && field.value === 0 ? '' : field.value}
          error={!!error}
          helperText={error?.message}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {other.icon}
              </InputAdornment>
            ),
          }}
          {...other}

        />




      )}
    />


  );
}
