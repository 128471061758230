import { useContext, createContext, useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import requestNewAccessToken from './requestNewAccessToken';
import dataEncrpt from './dataEncry';
import dataDecrypt from './dataDescry';

const kpanel = process.env.REACT_APP_KPANEL;
const kpanelre = process.env.REACT_APP_KPANELOTHER;
const HOSTNAME = process.env.REACT_APP_HOSTNAME;
const DOMAINCOOKIE = process.env.REACT_APP_DOMAINCOOKIE;
const NAMECOOKIEFULL = process.env.REACT_APP_NAMECOOKIEFULL;
const PORTVALID = process.env.REACT_APP_PORTVALID;
const location = window.location;

const AuthContext = createContext({
  isAuthenticated: false,
  isloading: () => {},
  setAccessTokenAndRefreshToken: () => {},
  saveUser: () => {},
  getUser: () => {},
});

export function AuthProvider({ children }) {
  //
  const [cookies, setCookie, removeCookie] = useCookies();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isloading, setIsLoading] = useState(true);

  function saveUser(userData) {
    setAccessTokenAndRefreshToken(userData);
  }

  function setAccessTokenAndRefreshToken(accessToken) {
    if (location.port === PORTVALID) {
      setCookie(NAMECOOKIEFULL, dataEncrpt(accessToken), { path: '/' });
    } else {
      setCookie(NAMECOOKIEFULL, dataEncrpt(accessToken), { path: '/', domain: DOMAINCOOKIE });
    }

    removeCookie('cookieOTP', { path: '/' });
    removeCookie('C307', { path: '/' });
    window.location.reload();
  }

  async function getNewAccessToken(refreshToken) {
    const token = await requestNewAccessToken(refreshToken);
    if (token) {
      return token;
    }
  }
  async function checkAuth() {
    try {
      const token = dataDecrypt(cookies);
      if (token) {
        getNewAccessToken(token)
          .then(async (newToken) => {
            if (newToken) {
              setIsAuthenticated(true);

              if (location.hostname === HOSTNAME) {
                window.location.href = kpanelre;
              } else {
                window.location.href = kpanel;
              }
            } else {
              setIsAuthenticated(false);
            }
          })
          .catch((error) => {
            setIsAuthenticated(false);
          });
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    checkAuth();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isloading,
        saveUser,
        setAccessTokenAndRefreshToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
  // #
}

export const useAuth = () => useContext(AuthContext);
