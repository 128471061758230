import * as React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import { Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css';
import FormHelperText from '@mui/material/FormHelperText';



PhoneInputnumber.propTypes = {
  name: PropTypes.string,
};


export default function PhoneInputnumber({ name, ...other }) {

  return (
    <Controller
      name={name}
      control={other.control}
      render={({ field, fieldState: { error }, }) => (


        <FormControl error={!!error}  >
          <PhoneInput
            {...field}
            specialLabel={false}
            label={other.label}
            value={field.value}
            onChange={field.onChange}
            country={'ve'}
            onlyCountries={['ve',]}
            masks={{ ve: '(...) ...-....' }}
            areaCodes={{ ve: ['412', '414', '416', '424', '426', '02'], }}
            countryCodeEditable={false}
            isValid={() =>
              error?.type ? '' : true
            }
            inputStyle={{
              backgroundColor: "#F9FAFB",
              width: other.width,
              borderRadius: 10,
              label:'telefono'
              
            }
          
          }
          />

          {error?.type === 'required' &&
            <FormHelperText error id="component-error-text">
              {error?.message}
            </FormHelperText>
          }{error?.type === 'min' &&
            <FormHelperText error id="component-error-text">
              {error?.message}
            </FormHelperText>
          }{error?.type === 'matches' &&
            <FormHelperText error id="component-error-text">
              codigo no valido
            </FormHelperText>
          }
        </FormControl>




      )}
    />
  );
}
