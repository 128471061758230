import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
/*
export const MarkerIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
 // iconAnchor: [0, 0]

});
*/
//  10.471447, -66.876991
export const MarkerIcon = L.icon({
  iconUrl: "/favicon/icoMarker.png",
  shadowUrl: icon,
 // iconAnchor: [0, 0],
  
});

