import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../sections/auth/AuthProvider';

const login = process.env.REACT_APP_LOGIN; // Conection Api

export default function ProtectedRoute() {
  const auth = useAuth();

  return auth.isAuthenticated ? <Outlet /> : <Navigate to={login} />;
}
