import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import {
  Card,
  // Button,
  Link,
  Container,
  Typography,
  Box,
} from '@mui/material';
import AppBar from '@mui/material/AppBar';

// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Copyright from '../components/footer/Copyright';
import LinearProgresss from '../components/progress/ProgressLinea';
import ProgressCircular from '../components/progress/ProgressCircular';
import ModalAlert from '../components/modal/ModalAlert';
import FullScreenDialog from '../components/modal/FullScreenDialog';
import { url, TransitionAlerts } from '../components/hook-form';
// sections
import { RegisterForm } from '../sections/auth/register';
import Logo from '../components/Logo';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  backgroundImage: `url(/static/iconsyimages/Chef_karta_inicio.png)`,
  backgroundRepeat: 'no-repeat',
  backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
  backgroundSize: 'cover',
  backgroundPosition: 'contain',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '10px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const registroUser = process.env.REACT_APP_user_register;

export default function RegisterCompany() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  const Cookies = useResponsive('Cookies');

  const [Token, setCookies] = Cookies;

  const CONEC_API = url;

  const [StatusAlert, setStatusAlert] = useState(false);
  const [Messenge, setMessenge] = useState('');
  const [Severity, setSeverity] = useState('success');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [Openmodal, setOpenmodal] = useState(false);
  const [OpenFullScr, setOpenFullScr] = useState(false);
  const [idstatus, setIdstatus] = useState(0);

  const handleClose = async () => {
    setOpenmodal(false);
    setOpenFullScr(true);
  };

  const onSubmit = async (values) => {
    setStatusAlert(false);
    const val = {
      idusertype: 1,
      idcompanytype: Number(values.companytype.id),
      username: `${values.companyName}`.replace(/\s+/g, ''),
      password: '$2b$12$p.zhkz67JDouyyDzuoRlE.289/zyudnuPhj/ACl6YYFu3WVCFe.GK',
      iddocumenttype: values.documenttype.id,
      di: values.documentnumber,
      fullname: values.companyName,
      email: values.email.toLowerCase(),
      phone: values.Phone,
      description: null,
      dob: 'Fri, 01 Jan 1999 00:00:00 GMT',
      idstatus: 1,
    };
    console.log(val);
    try {
      setLoading(true);
      const CONFIG_JSON = {
        method: 'POST',
        headers: {
          referrerPolicy: 'unsafe_url',
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'https://kartaqr.com',
          mode: 'same-origin',
        },
        body: JSON.stringify(val),
      };
      const res = await fetch(`${CONEC_API}${registroUser}`, CONFIG_JSON);
      const RES_API = await res.json();

      if (res.status === 500) {
        setLoading(false);
        setStatusAlert(true);
        setSeverity('error');
        setMessenge('Ocurrio un error. "@_X" ');
        window.setTimeout(() => {
          setOpen(false);
          setStatusAlert(false);
        }, 3000);
      }

      if (res.status === 400) {
        setLoading(false);

        if (RES_API.userInfo.idstatus === 1) {
          setOpenmodal(true);
          setCookies('C307', RES_API.userInfo);
          setIdstatus(RES_API.userInfo.idstatus);
        }

        if (RES_API.userInfo.idstatus > 3) {
          setLoading(false);
          setStatusAlert(true);
          setSeverity('info');
          setMessenge('Disculpe, este usuario ya existe.');
          window.setTimeout(() => {
            setOpen(false);
            setStatusAlert(false);
          }, 3000);
        }

        if (RES_API.userInfo.idstatus === 2 || RES_API.userInfo.idstatus === 3) {
          setOpenmodal(true);
          setCookies('C307', RES_API.userInfo);
          setIdstatus(RES_API.userInfo.idstatus);
        }
      }

      if (res.status === 200) {
        setLoading(false);

        setOpen(!open);
        window.setTimeout(() => {
          setCookies('C307', RES_API.userInfo);
          setOpenFullScr(true);
        }, 2000);
      }
    } catch (error) {
      if (error) {
        setLoading(false);
        setStatusAlert(true);
        setSeverity('error');
        setMessenge('Sin conexion. :(');
        window.setTimeout(() => {
          setStatusAlert(false);
        }, 3000);
      }
    }
  };

  return (
    <Page title="Register">
      <ModalAlert open={Openmodal} handleClose={handleClose} />

      <ProgressCircular open={open} />
      {<FullScreenDialog open={OpenFullScr} status={idstatus} />}
      {loading && (
        <>
          <AppBar sx={{ position: 'fixed' }}>
            <Box>
              <LinearProgresss sx={{ position: 'absolute', top: '5px', left: 0, right: 0 }} loading={loading} />
            </Box>
          </AppBar>
        </>
      )}

      <TransitionAlerts
        Messenge={Messenge}
        statusAlert={StatusAlert}
        severity={Severity}
        handleClose={() => setStatusAlert(false)}
      />

      <RootStyle>
        {mdUp && <SectionStyle />}

        <Container>
          <Box
            sx={{
              mt: 10,
              mb: -10,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <RouterLink to="/">
              {
                <Logo />
              }
            </RouterLink>
            <Typography variant="h4" gutterBottom>
              Listos para servirte.
            </Typography>
            <Typography align="center" sx={{ color: 'text.secondary', mb: 2 }}>
              Al registrar tu restaurant, vas a poder crear un menú Qr.
            </Typography>
          </Box>
          <ContentStyle>
            <RegisterForm onSubmit={onSubmit} />
            {!smUp && (
              <Typography variant="body2" sx={{ mt: 3, textAlign: 'center' }}>
                Already have an account?{' '}
                <Link variant="subtitle2" to="/login" component={RouterLink}>
                  Login
                </Link>
              </Typography>
            )}
            <Copyright />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
