import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import PassForm from './PassForm';

// ----------------------------------------------------------------------
PassForm.propTypes = {
  name: PropTypes.string,
};

export default function Review({ name, ...other }) {
  return (
    <>
      <Typography align="center" variant="h4" gutterBottom>
        ¡Ahora, ingresa una clave unica y listo!
      </Typography>
      <Typography align="center" variant="subtitle1" sx={{ color: 'text.secondary', mb: 3 }}>
        Y ya abremos finalizado este importante paso.{' '}
      </Typography>
      <PassForm id={other.iduser} setActiveStep={other.setActiveStep} activeStep={other.activeStep} />
    </>
  );
}
