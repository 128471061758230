import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Controller } from 'react-hook-form';
import Box from '@mui/material/Box';

CountrySelect.propTypes = {
  name: PropTypes.string,
};

export default function CountrySelect({ name, ...other }) {

  return (
    <Controller
      name={name}
      control={other.control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
         // loading={loadingStatus}
          // loadingText={'cargando...'}
          noOptionsText={other.Optins}
          sx={{ width: other.width }}
          {...field}
          options={other.apiconect}
          getOptionLabel={(option) => option.name || ''}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              {option.name}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={other.label}
              error={!!error}
              helperText={error ? 'Es importate saber diferenciarlo.' : ''}
              value={params}
            />
          )}
          isOptionEqualToValue={(option, value) => value.id === undefined || value.id === '' || option.id === value.id}
          onChange={(_, data) => field.onChange(data)}
        />
      )}
    />
  );
}
