import * as React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import FormHelperText from '@mui/material/FormHelperText';
import LocationOffIcon from '@mui/icons-material/LocationOff';

export default function Welcome() {
  return (
    <>
      <Typography variant="h6">":("<LocationOffIcon />  No tenemos acceso a tu localización GPS.</Typography>
      <List disablePadding>
        <Typography gutterBottom>
          Esto no nos permite obtener la ubicación exacta de tu negocio a través de este dispositivo.
        </Typography>
        <FormHelperText> "TE RECOMENDAMOS PERMITIR EL ACCESO A TU UBICACIÓN".</FormHelperText>
      </List>
    </>
  );
}
