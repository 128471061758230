import React, { useState } from 'react';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import bcrypt from 'bcryptjs';
// @mui
import { LoadingButton } from '@mui/lab';
// components
// import Iconify from '../../../../components/Iconify';
import ProgressCircular from '../../../../components/progress/ProgressCircular';
import useResponsive from '../../../../hooks/useResponsive';
import { FormProvider, TextFieldicon, AlertsCinta } from '../../../../components/hook-form';
import url from '../../../../hooksApi';

// ----------------------------------------------------------------------
PassForm.propTypes = {
  name: PropTypes.string,
};

export default function PassForm({ name, ...other }) {
  const navigate = useNavigate();

  const Cookies = useResponsive('Cookies');
  const [Token, setCookies] = Cookies;
  const [showPassword, setShowPassword] = useState(false);
  const [data, setArrayUser] = React.useState(other.data);
  const [iduser, setId] = useState(other.id);
  const [status, setStatus] = useState(false);
  const [Messenge, setMessenger] = useState('');
  const [Severity, setSeverity] = useState('error');
  const [StatusAlert, setStatusAlert] = useState(false);

  const [openProgress, setOpenProgress] = useState(false);

  const salt = bcrypt.genSaltSync(10);

  const RegisterSchema = Yup.object().shape({
    pass: Yup.string()
      .required('No se proporcionó contraseña.')
      .min(8, 'La contraseña es demasiado corta; debe tener un mínimo de 8 caracteres.')
      .matches(/[a-zA-Z]/, 'La contraseña debe contener letras.'),
    pass2: Yup.string().oneOf([Yup.ref('pass'), null], 'Las contraseñas deben coincidir'),
  });

  const defaultValues = {
    pass: '',
    pass2: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (e) => {
    const passbcry = bcrypt.hashSync(e.pass, salt);

    const values = {
      id: other.id,
      password: e.pass,
    };

    try {
      const CONFIG_JSON = {
        method: 'PUT',
        headers: {
          referrerPolicy: 'unsafe_url',
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'https://kartaqr.com',
          mode: 'same-origin',
        },
        body: JSON.stringify(values),
      };

      const res = await fetch(`${url}user/update/register/password`, CONFIG_JSON);
      const resapi = await res.json();
      if (res.status === 200) {
        setOpenProgress(true);
        setCookies('C307', resapi);
        other.setActiveStep(other.activeStep + 1);
      }

      if (res.status !== 200) {
        setStatusAlert(true);
        //  setMessenger(resapi.message);
        setMessenger('Ocurrio un error... intente nuevamente.');
        setSeverity('error');

        window.setTimeout(() => {
          //   setSucesssend(false);
          //  setSucesscolor('primary');
          setStatusAlert(false);
          //   setLoading(false);
          //   setDisabledbtn(true);
        }, 3000);
      }

      window.setTimeout(() => {
        setStatus(false);
      }, 4000);
    } catch (error) {
      console.log('error sin conexion');

      if (error) {
        setStatus(true);
        setMessenger('Ups, error de conexión...');
        setSeverity('error');
      }

      window.setTimeout(() => {
        setStatus(false);
      }, 4000);
    }
  };
  /*
  const onSubmitejem = async () => {
    navigate('/dashboard', { replace: true });
  };
*/
  return (
    <>
      <ProgressCircular open={openProgress} />
      <AlertsCinta Messenge={Messenge} statusAlert={StatusAlert} severity={Severity} />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {/*   <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextFieldicon
            id="username"
            name="username"
            label="Nombre usuario"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldicon
            type="number"
            id="code"
            name="code"
            label="Documento legal"
            />
        </Grid>
  </Grid>{" "} */}

        {/* <Typography
        sx={{
          marginTop: 3,
        }}
        variant="h6"
        gutterBottom
        >
        Cambio de correo y contraseña
      </Typography> */}

        <Grid container spacing={3}>
          {/* <Grid item xs={12}>
          <TextFieldicon id="email" name="email" label="Correo electronico" />
      </Grid> */}

          <Grid
            item
            xs={12} // sm={6}
          >
            <TextFieldicon type="password" id="pass" name="pass" label="Escribe una contraseña" />
          </Grid>
          <Grid
            item
            xs={12} // sm={6}
          >
            <TextFieldicon type="password" id="pass2" name="pass2" label="Repetir la contraseña" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LoadingButton sx={{ mt: 2, mb: 2 }} fullWidth type="submit" variant="contained" loading={isSubmitting}>
              Guardar contraseña
            </LoadingButton>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
}

/* }

/* import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../../components/hook-form';

// ----------------------------------------------------------------------

export default function PassForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {
    navigate('/dashboard', { replace: true });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {/* <RHFTextField name="email" label="Email address" /> */
/*
<RHFTextField
name="password"
label="Password"
type={showPassword ? 'text' : 'password'}
InputProps={{
endAdornment: (
<InputAdornment position="end">
<IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
<Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
</IconButton>
</InputAdornment>
),
}}
/>
<RHFTextField
name="password"
label="Password"
type={showPassword ? 'text' : 'password'}
InputProps={{
endAdornment: (
<InputAdornment position="end">
<IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
<Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
</IconButton>
</InputAdornment>
),
}}
/>
</Stack>

<Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
<RHFCheckbox name="remember" label="Remember me" />
<Link variant="subtitle2" underline="hover">
Forgot password?
</Link>
</Stack>

<LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
Login
</LoadingButton>
</FormProvider>
);
}
*/
