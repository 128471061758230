import React, { useState, useEffect } from 'react'
// import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';




ModalAlert.propTypes = {
    name: PropTypes.string,
};
export default function ModalAlert({ name, ...other }) {

    const [open, setOpen] = useState(false);

    useEffect(() => {

        setOpen(other.open);

    }, [other]);


    return (
        <div>

            <Dialog
                name={name}
                open={open}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"¡Hola, Qué alegría tenerte de vuelta!"}</DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Nos encantaría que concluyeras este sencillo registro y que
                        puedas expandir tu negocio con Karta Qr.
                    </DialogContentText>
                </DialogContent>

                <DialogActions>

                    <Button onClick={other.handleClose}>Continuar...</Button>

                </DialogActions>
            </Dialog>
        </div>
    );
}