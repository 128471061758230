import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { Welcome, CoordManual } from '.';

const steps = ['welcome', 'welcome'];

getStepContent.propTypes = {
  name: PropTypes.string,
};

function getStepContent(step) {
  switch (step) {
    case 0:
      return <Welcome />;
    case 1:
      return <CoordManual />;
    case 2:
      return <Welcome />;
    default:
      throw new Error('Unknown step');
  }
}

TourMaps.propTypes = {
  name: PropTypes.string,
};

export default function TourMaps({ name, ...other }) {
  // const Cookies = useResponsive('Cookies');
  // const [Token, setCookies] = Cookies;

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <Container name={name} component="main" maxWidth="sm" sx={{ mb: 4 }}>
      <Paper variant="outlined" sx={{ my: { xs: 1, md: 1 }, p: { xs: 2, md: 3 } }}>
        <>
          {activeStep === steps.length ? (
            <>
              <Typography variant="h5" gutterBottom>
                Gracias por ver el TourMap
              </Typography>
              <Typography variant="subtitle1">
                Configuremos tu ubicación exacta. Esto ayudara a tus comensales a que puedan llegar sin dificultad.
              </Typography>
              <Button disabled={other.enableNext} variant="contained" onClick={other.handleOk} sx={{ mt: 3, ml: 1 }}>
                Ok
              </Button>
            </>
          ) : (
            <>
              {getStepContent(activeStep)}

              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    Back
                  </Button>
                )}

                <Button disabled={other.enableNext} variant="contained" onClick={handleNext} sx={{ mt: 3, ml: 1 }}>
                  {activeStep === steps.length - 1 ? 'Place order' : 'Next'}
                </Button>
              </Box>
            </>
          )}
        </>
      </Paper>
    </Container>
  );
}
