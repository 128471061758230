import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import PropTypes from 'prop-types';
import LocationOffIcon from '@mui/icons-material/LocationOff';

SnackAlert.propTypes = {
    Messenge: PropTypes.string,
};

export default function SnackAlert({ Messenge, ...other }) {

    const [open, setOpen] = useState(false);

    const defa = 'Deshabilitado';
    useEffect(() => {

        setOpen(other.open);

    }, [other]);


    const handleClose = () => {

        setOpen(false);
    };

    window.setTimeout(() => {
        setOpen(false);
    }, 2000);

    const action = (
        <>
            <Button size="small" onClick={other.handleClose}>
                activa tu geolocalización
            </Button>
        </>
    );
    const etiq = (
        <>
            Deshabilitado <LocationOffIcon fontSize="small" />

        </>
    );

    return (
        <>
            <Snackbar
                severity="error"
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                message={etiq}
                action={action}
            />
        </>
    );
}