import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';
import RegisterConfigurations from '../../sections/auth/register/RegisterConfigurations';
import useResponsive from '../../hooks/useResponsive';
import LinearProgresss from '../progress/ProgressLinea';
import { TransitionAlerts } from '../hook-form';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

FullScreenDialog.propTypes = {
  name: PropTypes.string,
};

export default function FullScreenDialog({ name, ...other }) {
  const Cookies = useResponsive('Cookies');
  const url = process.env.REACT_APP_API_KARTA; // Conection Api

  const [Token, setCookies] = Cookies;
  const [Messenge, setMessenge] = useState('');
  const [StatusAlert, setStatusAlert] = useState(false);
  const [Severity, setSeverity] = useState('success');

  const [open, setOpen] = useState(false);
  const [openOTP, setOpenOTP] = useState(false);
  const [DisabledtoButton, setDisabledtoButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [DisabledButton, setDisabledButton] = useState(false);
  const [otp, setOtp] = useState('');
  const [isDisabled, setIsDisabled] = useState('');
  const [hasErrored, setHasErrored] = useState('');
  const [enableNext, setEnableNext] = useState(true);

  window.setTimeout(() => {
    if (other.status > 1) {
      setDisabledButton(true);
      setDisabledtoButton(true);
      setEnableNext(false);
    }
  }, 10);

  // #########################################
  // Actualizar datos:   #####################
  // #########################################

  const onSubmit = async (values) => {
    const fechaaa = Token.C307.dob.substr(0, 16);
    const fechatoken = new Date(fechaaa).toLocaleDateString('en-US');

    const val = {
      username: values.Username,
      di: values.di,
      fullname: values.companyName,
      email: values.email.toLowerCase(),
      phone: values.Phone,
      dob: values.dob.toLocaleDateString(),
    };

    const val2 = {
      username: Token.C307.username,
      di: Token.C307.di,
      fullname: Token.C307.fullname,
      email: Token.C307.email,
      phone: Token.C307.phone,
      dob: fechatoken,
    };

    const isEqual = JSON.stringify(val) === JSON.stringify(val2);

    setCookies('cookieOTP', [Token.C307.email, Token.C307.id]);

    setDisabledButton(true);

    if (isEqual === false) {
      const valuePut = {
        username: values.Username,
        di: values.di,
        fullname: values.companyName,
        email: values.email.toLowerCase(),
        phone: values.Phone,
        dob: values.dob,
      };
      try {
        const CONFIG_JSON = {
          method: 'PUT',
          headers: {
            referrerPolicy: 'unsafe_url',
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            mode: 'same-origin',
          },
          body: JSON.stringify(valuePut),
        };
        const res = await fetch(`${url}user/register/update/${Token.C307.id}`, CONFIG_JSON);
        const RES_API = await res.json();

        if (res.status === 200) {
          window.setTimeout(() => {
            setOpenOTP(true);
            setDisabledtoButton(true);
          }, 300);

          setCookies('C307', RES_API.userInfo);
        }

        if (res.status === 500) {
          setHasErrored(true);
          setStatusAlert(true);
          setSeverity('error');
          setMessenge("Ocurrio un error. '_'");
          // setMessenge(RES_API.message);

          window.setTimeout(() => {
            setHasErrored(true);
            setStatusAlert(false);
            setDisabledtoButton(false);

            setDisabledButton(false);
          }, 3000);
        }
        if (res.status === 400) {
          setHasErrored(true);
          setStatusAlert(true);
          setSeverity('error');
          setMessenge(RES_API.message);

          window.setTimeout(() => {
            setHasErrored(true);
            setStatusAlert(false);
            setDisabledtoButton(false);

            setDisabledButton(false);
          }, 3000);
        }
      } catch (error) {
        setStatusAlert(true);

        window.setTimeout(() => {
          setStatusAlert(false);
        }, 3000);

        setLoading(false);
        setOpenOTP(false);
        setDisabledtoButton(false);
        setDisabledButton(false);
      }
    } else {
      window.setTimeout(() => {
        setOpenOTP(true);
        setDisabledtoButton(true);
      }, 300);
    }
  };

  const CloseOtp = () => {
    setLoading(false);
    setOpenOTP(false);
    setDisabledtoButton(false);
    setDisabledButton(false);
  };

  // #########################################
  // Para evaluar OTP:   #####################
  // #########################################

  const handleChangeOTP = async (newValue) => {
    setOtp(newValue);

    if (newValue.length > 5) {
      setHasErrored(true);
      setStatusAlert(true);
      setSeverity('info');
      setMessenge('Espere un momento...');

      try {
        const res = await fetch(`${url}user/codeotp/${newValue}/${Token.C307.id}`);
        const RES_API = await res.json();
        console.log(RES_API.userInfo);

        if (res.status === 200) {
          setCookies('C307', RES_API.userInfo);
          window.setTimeout(() => {
            setLoading(false);
            setIsDisabled(true);
            setOpenOTP(false);
            setDisabledButton(true);
            setEnableNext(false);
            setStatusAlert(false);
          }, 300);
        }

        if (res.status === 400) {
          setStatusAlert(true);
          setSeverity('info');
          setMessenge('El codigo ha expirado.');
          window.setTimeout(() => {
            setHasErrored(true);
            setStatusAlert(false);
          }, 3000);
        }

        if (res.status === 404) {
          setHasErrored(true);
          setStatusAlert(true);
          setSeverity('error');
          setMessenge('Este codigo es incorrecto!');

          window.setTimeout(() => {
            setHasErrored(true);
            setStatusAlert(false);

            console.log(StatusAlert);
          }, 3000);
        }
      } catch (error) {
        setStatusAlert(true);

        window.setTimeout(() => {
          setStatusAlert(false);
        }, 3000);

        setLoading(false);
        setOpenOTP(false);
        setDisabledtoButton(false);
        setDisabledButton(false);
      }
    } else {
      setHasErrored('');
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(other.open);
  }, [other]);

  return (
    <div>
      {loading && (
        <>
          <AppBar sx={{ position: 'fixed' }}>
            <Box>
              <LinearProgresss sx={{ position: 'absolute', top: '5px', left: 0, right: 0 }} loading={loading} />
            </Box>
          </AppBar>
        </>
      )}

      <Dialog name={name} fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <TransitionAlerts
          Messenge={Messenge}
          statusAlert={StatusAlert}
          severity={Severity}
          handleClose={() => setStatusAlert(false)}
        />

        <AppBar sx={{ position: 'fixed' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Verificación de registro Karta Qr
            </Typography>
          </Toolbar>
        </AppBar>

        <RegisterConfigurations
          onSubmit={onSubmit}
          openOTP={openOTP}
          CloseOtp={CloseOtp}
          DisabledButton={DisabledButton}
          DisabledtoButton={DisabledtoButton}
          handleChangeOTP={handleChangeOTP}
          otp={otp}
          isDisabled={isDisabled}
          hasErrored={hasErrored}
          enableNext={enableNext}
          AlertsCinta={StatusAlert}
          Severity={Severity}
          Messenge={Messenge}
        />
      </Dialog>
    </div>
  );
}
