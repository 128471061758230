import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';


AlertsCinta.propTypes = {
  Messenge: PropTypes.string,
};

export default function AlertsCinta({ Messenge, ...other }) {

  const [open, setOpen] = useState(false);

  useEffect(() => {

    setOpen(other.statusAlert);

  }, [other]);



  return (
    <Box sx={{ width: '100%' }}>
      <Collapse in={open}>
        <Alert
          severity={other.severity}
        
          sx={{ mb: 2 }}
        >
          {Messenge}
        </Alert>
      </Collapse>
     
    </Box>
  );
}