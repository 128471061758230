import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SaveIcon from '@mui/icons-material/Save';
import LocationOffIcon from '@mui/icons-material/LocationOff';

SnackSave.propTypes = {
    Messenge: PropTypes.string,
};

export default function SnackSave({ Messenge, ...other }) {

    const [open, setOpen] = useState(false);

    useEffect(() => {

        setOpen(other.open);

    }, [other]);


    const handleClose = () => {
        setOpen(false);
    };

    const action = (
        <>
            <Button
               onClick={other.oksave}>
                Aceptar  <SaveIcon fontSize="small" />
            </Button>

        </>
    );

    const etiq = (
        <Typography>
            ¿Guardar ubicación?
        </Typography>
    );

    return (
        <Stack spacing={3} sx={{ Width: 500 }}>

            <Snackbar
                open={open}
                onClick={other.handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                message={etiq}
                action={action}

            />
        </Stack>
    );
}