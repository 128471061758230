import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import {
    LinearProgress,
  } from '@mui/material';

LinearProgresss.propTypes = {
    name: PropTypes.string,
};

export default function LinearProgresss({ name, ...other }) {

    const [open, setOpen] = useState(false);


    useEffect(() => {

        setOpen(other.loading);

    }, [other]);



    return (

            <LinearProgress open={open} name={name} />

    );
}