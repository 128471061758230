import { useState } from 'react';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import FoodBankIcon from '@mui/icons-material/FoodBank';
import LoadingButton from '@mui/lab/LoadingButton';
import BadgeIcon from '@mui/icons-material/Badge';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormProvider,
  TextFieldicon,
  TextFieldPhone,
  TextFieldDocument,
  InputCalend,
} from '../../../../components/hook-form';
import ModalOTP from '../../../../components/modal/ModalOTP';
// hook consulta
import useSelectConsult from '../../../../hooks/consult/useConsultCombo';

// import MapaGit from '../../../../components/mapa/MapaGit';

AddressForm.propTypes = {
  name: PropTypes.string,
};

export default function AddressForm({ name, ...other }) {
  const [typedc] = useSelectConsult('documenttype/company');

  const [day, setDob] = useState(dayjs());

  const correo = other.data.C307.email;
  const firstName = other.data.C307.fullname;
  const user = other.data.C307.username;
  const iddocument = other.data.C307.di;
  const tphone = other.data.C307.phone;
  const typedi = other.data.C307.iddocumenttype;
  const fecha = other.data.C307.dob;

  function typeComerce(arr, typedi) {
    
    for (let i = 0; i < arr.length; i += 1) {
      if (arr[i].id === typedi) {
        return arr[i].code;
      }
    }

    return 'D';
  }

  const phoneRegExp = /24|28|29|27|25|26|21|41|42/;
  const code = /[\s-/^*@^a-zA-Z!-"#$%&/()+=?¡!¿'\\]/gi;

  const RegisterSchema = Yup.object().shape({
    companyName: Yup.string().required('Nombre de su empresa no puede ir vacio').min(4, 'lo minimo son 4 caracteres').max(30, 'El usuario debe ser un maximo de 30'),
    Username: Yup.string().required('Nombre de usuario no puede estar vacio').min(4, 'lo minimo son 4 caracteres').max(20, 'El usuario debe ser un maximo de 20'),
    di: Yup.string()
      .min(9, 'Faltan numeros')
      .max(12, 'Maximo de 10 digitos ')
      .transform((value) => value.replace(code, ''))
      .required('Para futuras facturacciones, es requerido.'),

    dob: Yup.date()
      .max(day, 'No puede ser hoy')
      .typeError('Se requiere la fecha')
      .nullable()
      .required('No puede estar vacio')
      .transform((value) => value),

    email: Yup.string()
      .email('Ingrese un Email valido ejemplo: Karta-bussines@karta.com')
      .required('El Email es Requerido.'),

    Phone: Yup.string()
      .min(12, 'El numero de telefono debe contener 12 digitos')
      .max(12, 'Maximo de 10 digitos ')
      .transform((value) => value.replace(code, ''))
      .matches(phoneRegExp, 'Codigo telefono invalido')
      .required('Ingrese numero de telefono.'),
  });

  const documentapi = iddocument;
  const tdc = typeComerce(typedc, typedi);
  const maskdocument = `${tdc} 999999999-9`;

  const fechaini = fecha !== 'Fri, 01 Jan 1999 00:00:00 GMT' ? fecha : '';

  const fechaaa = fechaini.substr(0, 16);
  const fechatoken = new Date(fechaaa).toLocaleDateString('en-US');

  const date = other.DisabledButton ? fecha : fechatoken;

  const defaultValues = {
    companyName: firstName,
    Username: user,
    di: documentapi,
    dob: date,
    email: correo,
    Phone: tphone,
  };

  const methods = useForm({ resolver: yupResolver(RegisterSchema), defaultValues });

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    watch,
    register,
  } = methods;

  return (
    <>
      <ModalOTP
        open={other.openOTP}
        handleClose={other.CloseOtp}
        handleChangeOTP={other.handleChangeOTP}
        isDisabled={other.isDisabled}
        valueOTP={other.otp}
        hasErrored={other.hasErrored}
        StatusAlert={other.StatusAlert}
        Severity={other.Severity}
        Messenge={other.Messenge}

      />

      <Typography align="center" name={name} variant="h4" gutterBottom>
        Comprueba tu empresa.
      </Typography>
      <Typography align="justify" sx={{ color: 'text.secondary' }} name={name} variant="h7" gutterBottom>
        Con esto brindaremos seguridad a tus datos para futuras compra desde la plataforma. Puedes editar tus datos aqui
        si lo requieres.
      </Typography>
      <Divider variant="middle" sx={{ mt: 2, mb: 2 }}>
        <Chip label="Usuario" />
      </Divider>
      <FormProvider methods={methods} onSubmit={handleSubmit(other.onSubmit)}>
        
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextFieldicon
              disabled={other.DisabledButton}
              id="companyName"
              name="companyName"
              label="Empresa"
             icon={<FoodBankIcon />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldicon
              disabled={other.DisabledButton}
              id="Username"
              name="Username"
              label="Usuario"
              icon={<AlternateEmailIcon />}
            />
          </Grid>
          <Divider />
          <Grid item xs={12} sm={6}>
            <TextFieldDocument
              mask={maskdocument}
              disabled={other.DisabledButton}
              autoComplete="given-name"
              variant="filled"
              id="di"
              name="di"
              label="Documento"
              icon={<BadgeIcon />}
            />
          </Grid>
          {''}
          <Grid item xs={12} sm={6}>
            <TextFieldPhone
              disabled={other.DisabledButton}
              name="Phone"
              label="Numero de telefono"
              icon={<PhonelinkSetupIcon />}
              mask="(+58) 999-999-9999"
              autoComplete="given-name"
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} sm={15}>
            <Divider variant="middle" sx={{ mt: 2, mb: 2 }}>
              <Chip  label="Fecha de registro comercial" />
            </Divider>
            <InputCalend name="dob" disabled={other.DisabledButton} label={'Fecha registro'} />
          </Grid>
          <Grid item xs={15}>
            <Divider variant="middle" sx={{ mt: 2, mb: 2 }}>
              <Chip label="Verifiquemos tu correo" />
            </Divider>

            <TextFieldicon
              disabled={other.DisabledButton}
              id="email"
              name="email"
              label="Correo corporativo"
              icon={<LocalPostOfficeIcon />}
            />

            <Typography
              variant="body2"
              align="justify"
              sx={{ color: 'text.secondary', mb: 2 }}
              name={name}
              gutterBottom
            >
              Enviaremos un codigo de comprobación a este correo.
            </Typography>

            <LoadingButton
              sx={{ mt: 2, mb: 2 }}
              disabled={other.DisabledtoButton}
              fullWidth
              // onClick={handleClick}
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Validar Email
            </LoadingButton>
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <TextField
              required
              id="city"
              name="city"
              label="Cuidad"
              fullWidth
              autoComplete="shipping address-level2"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField id="state" name="state" label="Estado/Provincia/Región" fullWidth variant="standard" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="zip"
              name="zip"
              label="Codigo Postal"
              fullWidth
              autoComplete="shipping postal-code"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="country"
              name="country"
              label="Pais"
              fullWidth
              autoComplete="shipping country"
              InputProps={{
                readOnly: true,
              }}
              defaultValue="Venezuela"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox color="secondary" name="saveAddress" value="yes" />}
              label="Usar esta dirección para detalles de pago"
            />
          </Grid>
            */}
        </Grid>
      </FormProvider>
    </>
  );
}
