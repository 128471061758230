import * as React from 'react';
import PropTypes from 'prop-types';
import 'dayjs/locale/fr';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// form
import { useFormContext, Controller } from 'react-hook-form';


InputCalend.propTypes = {
  name: PropTypes.string,
};

const locale = 'fr';

export default function InputCalend({ name, ...other }) {

  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (

        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>

          <DatePicker
            {...field}
            error={!!error}
            value={field.value}
            helperText={error?.message}
            disabled={other.disabled}
            fullwidth
            inputFormat="DD/MM/YYYY"
            label={other.label}
            mask="__/__/____"
            onChange={field.onChange}

            renderInput={(params) =>
              <TextField
                {...params}
                {...field}
                value={field.value}
                error={!!error}
                helperText={error?.message}
                {...other}
              />}
          />

        </LocalizationProvider>

      )}
    />
  );
}


