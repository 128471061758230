import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';



Contador.propTypes = {
  name: PropTypes.string,
};


export default function Contador({ name, ...other }) {

  const date = other.min.split(':');

  const Hr = date[0] === '00' ? 0 : date[0];
  const mint = date[1] === '00' ? 0 : date[1];
  const seg = date[2] === '00' ? 0 : date[2];


  const [mins, setMinutes] = useState(mint);
  const [secs, setSeconds] = useState(seg);

  useEffect(() => {

    const sampleInterval = setInterval(() => {

      if (secs > 0) {
        setSeconds(secs - 1);
      }

      if (secs === 0) {

        if (mins === 0) {

          clearInterval(sampleInterval);
          setMinutes(0)

        } else {

          setMinutes(mins - 1);
          setSeconds(59);

        }

      }

    }, 1000);


    return () => {
      clearInterval(sampleInterval);
    };



  });

  const segundos = secs < 10 ? `0${secs}` : secs;
  const minutos = mins < 10 ? `0${mins}` : mins;


  const time = `${minutos}:${segundos}`;


  return secs > 0 ? <>{other.icon} {time} </> : ('');

}