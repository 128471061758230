import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import {
    Backdrop,
    CircularProgress,
} from '@mui/material';
import {useAuth} from '../../sections/auth/AuthProvider';

ProgressCircular.propTypes = {
    name: PropTypes.string,
};

export default function ProgressCircular({ name, ...other }) {
    const auth = useAuth();

    const [open, setOpen] = useState(false);
    useEffect(() => {

        setOpen(other.open);

    }, [other]);

    return (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => 
        theme.zIndex.drawer + 1 }} open={open} >

            <CircularProgress color="inherit" />
            
        </Backdrop>

    );
}