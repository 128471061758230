import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography, Box, Avatar } from '@mui/material';

// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Copyright from '../components/footer/Copyright';
// sections
import Logo from '../components/Logo';
import AuthTypeUser from '../sections/auth/AuthTypeUser';

const login = process.env.REACT_APP_LOGIN; 
const comercioimg = process.env.REACT_APP_COMERCIO_IMG; 

console.log(comercioimg)
const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  backgroundImage: `${comercioimg}`,
  backgroundRepeat: 'no-repeat',
  backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
  backgroundSize: 'cover',
  backgroundPosition: 'contain',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Register() {
  // const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Register">
      <RootStyle>
        {mdUp && <SectionStyle />}

        <Container>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mt: { md: 10 }
            }}
          >
            <RouterLink to="/">
              { // <Avatar src="/static/Logo-c.png" sx={{ mt: 2, mb: 2, width: '50vh', height: 'auto' }} variant="rounded" />  
              <Logo />
              }
            </RouterLink>
            <Typography variant="h4" gutterBottom>
              Comience absolutamente gratis.
            </Typography>
            {/*  <Typography sx={{ color: 'text.secondary', mb: 3 }}>Usted decida que comer ahora.</Typography> */}
          </Box>
          <ContentStyle>
            <AuthTypeUser />

            <Typography variant="body2" sx={{ mt: 1, mb: 10, textAlign: 'center' }}>
              ¿Ya tienes una cuenta? {''}
              <Link variant="subtitle2" to={login} component={RouterLink}>
                Acceder
              </Link>
            </Typography>

            <Copyright />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
