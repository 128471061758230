import { useState, useEffect } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import { useAuth } from './sections/auth/AuthProvider';
import RegisterCompany from './pages/RegisterCompany';
import ProgressCircular from './components/progress/ProgressCircular';
import ProtectedRoute from './protectedRoute/ProtectedRoutes';

const register = process.env.REACT_APP_register;
const registerCompany = process.env.REACT_APP_RegisterCompany;
const login = process.env.REACT_APP_LOGIN;
// ----------------------------------------------------------------------
// <EndPoin />
function EndPoin() {
  const auth = useAuth();
  const [open, setOpen] = useState(true);

  useEffect(() => {

    if (!auth.isAuthenticated) {
      window.setTimeout(() => {
        setOpen(auth.isAuthenticated);
      }, 1500);
    }
  }, [auth.isAuthenticated]);

  return (
    <>
      <ProgressCircular open={open} />

      {useRoutes([
        {
          path: login,
          element: <Login />,
        },
        {
          path: register,
          element: <Register />,
        },
        {
          path: registerCompany,
          element: <RegisterCompany />,
        },
        {
          path: '/',
          element: <ProtectedRoute />,
          children: [
            { path: '/', element: <Navigate to="/*" /> },
            { path: '404', element: <NotFound /> },
            { path: '*', element: <Navigate to="/404" /> },
          ],
        },
        {
          path: '*',
          element: <Navigate to="/404" replace />,
        },
      ])}
    </>
  );
}

export default function Router() {
  return (
    <>
      <EndPoin />
    </>
  );
}
