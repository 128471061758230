import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_SECRET_KEY_LOCALSTORAGE;

export default function dataEncrpt(value) {
  try {
    return CryptoJS.AES.encrypt(JSON.stringify(value), secretKey).toString();
  } catch (error) {
    console.log('🚀 ~ file: data-encrypt.js:9 ~ dataEncrpt ~ error:', error);
  }
}
