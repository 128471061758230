import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Snackbar from '@mui/material/Snackbar';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { AddressForm, Review } from '.';
import useResponsive from '../../../hooks/useResponsive';
import { SnackAlert, SnackSave } from '../../../components/SnackAlert/index';
import MapaGit from '../../../components/mapa/geocoder-control';
import useCoordss from '../../../components/mapa/config';
import TourMaps from './TourMap';

const steps = ['Tus datos', 'Localidad', 'Contraseña'];

getStepContent.propTypes = {
  name: PropTypes.string,
};

function getStepContent(
  step,
  Token,
  onSubmit,
  openOTP,
  CloseOtp,
  DisabledButton,
  DisabledtoButton,
  isDisabled,
  hasErrored,
  handleChangeOTP,
  otp,
  AlertsCinta,
  Severity,
  Messenge,
  setActiveStep,
  iduser
) {
  switch (step) {
    case 0:
      return (
        <AddressForm
          data={Token}
          onSubmit={onSubmit}
          openOTP={openOTP}
          CloseOtp={CloseOtp}
          DisabledButton={DisabledButton}
          DisabledtoButton={DisabledtoButton}
          otp={otp}
          isDisabled={isDisabled}
          hasErrored={hasErrored}
          handleChangeOTP={handleChangeOTP}
          StatusAlert={AlertsCinta}
          Severity={Severity}
          Messenge={Messenge}
        />
      );
    case 1:
      return <MapaGit />;
    case 2:
      return <Review iduser={iduser} setActiveStep={setActiveStep} activeStep={step} />;
    default:
      throw new Error('Unknown step');
  }
}

RegisterConfigurations.propTypes = {
  name: PropTypes.string,
};

export default function RegisterConfigurations({ name, ...other }) {
  const navigate = useNavigate();
  const Cookies = useResponsive('Cookies');
  const [Token, setCookies] = Cookies;

  const status = Token.C307.idstatus;
  const iduser = Token.C307.id;

  const [map, setMap] = useState(null); // Cofig MAPA
  const [setstatus, setStatus] = useState(status);
  const [activeStep, setActiveStep] = useState(0);
  const [openSnak, setOpenSnak] = useState(false);
  const [hidden, setHidden] = useState(true);
  const [coordenateOpen, setCoordenateOpen] = useState(false); // popUp CoordManuales
  const [openrest, setOpenrest] = useState(false);
  const [setcoderr, setCode] = useState(401);
  const [Nmrandom, setNmrandom] = useState(false);
  const [disabledreset, setDisabledreset] = useState(false);
  const [marker, setMarker] = useState(false);
  const [Opensave, setOpensave] = useState(false);
  const [manualcoord, setMcoord] = useState(false);
  const [draggable, setDraggable] = useState(false); // Para mover puntero
  const [rest, setRest] = useState(false);
  const [copyok, setCopyok] = useState(false);

  const center = useCoordss(Nmrandom, manualcoord);

  const toggleDraggable = useCallback(() => {
    setDraggable((d) => !d);
    setOpensave((d) => (draggable ? !d : true));
  }, [draggable]);

  // NO OBTUVIMOS LAS COORDENADAS codigo 401 entra:
  if (center.erro === setcoderr) {
    window.setTimeout(() => {
      setOpenSnak(true);
      setHidden(true);
      setMarker(false);
    });
  }

  // //////////////////////////////////////////
  // Boton 'OK' de Modal TOUR
  const handleOk = () => {
    setDisabledreset(false);
    setCode(false); // para darle otro estatus
    setHidden(false);
    setOpenSnak(false);
    setCopyok(false);
  };

  // //////////////////////////////////////////
  // COORDENADAS MANUALES:::::::::::::::
  const onClickCoordenate = (values) => {
    const newcenter = {
      lat: values.lat * 1, // se multiplica * 1 para convertir a numerico.
      lng: values.lng * 1,
      address: values.address,
      zoom: 12,
    };
    setMcoord(newcenter);
    setNmrandom((d) => !d);
    setCoordenateOpen(false);
    setOpenrest(false);
    setMarker(false);
    setRest((d) => !d); // para hacer cambiar el useff
    window.setTimeout(() => {
      setMarker(true);
      setOpensave(true);
    }, 10);
  };

  // //////////////////////////////////////////
  // SI OBTUVIMOS COORDENADAS
  if (center.erro === 200) {
    window.setTimeout(() => {
      if (copyok) {
        setHidden(false);
        setOpenSnak(true);
        setMarker(true);
      } else {
        setHidden(false);
        setOpenSnak(false);
        setMarker(true);
      }
    }, 1);
  }

  const cancel = () => {
    setCoordenateOpen(false);
    setHidden(false);
    setDisabledreset(false);
  };

  const handleClose = () => {
    setOpenrest(false);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  // aqui de envia a la Api
  const oksave = () => {
    setOpenrest(false);
  };

  if (status === 3) {
    window.setTimeout(() => {
      setActiveStep(2);
    }, 2000);
  }

  if (status === 4) {
    window.setTimeout(() => {
      setActiveStep(3);
    }, 2000);
  }
  const handleBack = () => {
    navigate('/login', { replace: true });
  };
  return (
    <>
      {activeStep !== 1 ? (
        <Container
          name={name}
          component="main"
          maxWidth={setstatus === 2 && activeStep === 1 ? '' : 'sm'}
          sx={{ mb: 4 }}
        >
          <Paper variant="outlined" sx={{ my: { xs: 13, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Typography component="h1" variant="h4" align="center">
              Checkout
            </Typography>
            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <>
              {activeStep === steps.length ? (
                <>
                  <Typography variant="h5" gutterBottom>
                    ¡BIENVENIDO!
                  </Typography>
                  <Typography variant="subtitle1">
                    Ahora podrás iniciar sesión con tu empresa dandole a Siguiente.
                  </Typography>
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    Siguente
                  </Button>
                </>
              ) : (
                <>
                  {getStepContent(
                    activeStep,
                    Token,
                    other.onSubmit,
                    other.openOTP,
                    other.CloseOtp,
                    other.DisabledButton,
                    other.DisabledtoButton,
                    other.isDisabled,
                    other.hasErrored,
                    other.handleChangeOTP,
                    other.otp,
                    other.AlertsCinta,
                    other.Severity,
                    other.Messenge,
                    other.setActiveStep,
                    iduser
                  )}
                  {status === 2 && (
                    <Button disabled={other.enableNext} variant="contained" onClick={handleNext} sx={{ mt: 3, ml: 1 }}>
                      {activeStep === steps.length - 1 ? 'Place order' : 'Siguiente'}
                    </Button>
                  )}
                </>
              )}
            </>
          </Paper>
        </Container>
      ) : (
        <>
          <Snackbar
            sx={{
              my: { xs: 6, md: 6 },
              p: { xs: 3, md: 3 },
              '& .MuiSnackbarContent-root': { backgroundColor: 'background.paper' },
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={openSnak}
            message={
              <Paper sx={{ my: { xs: -1, md: -1 }, p: { xs: -1, md: -1 } }}>
                <Typography component="h3" variant="h7" align="center">
                  Corrección de datos
                </Typography>

                <Stepper activeStep={activeStep} sx={{ pt: 1, pb: 2 }}>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>

                <Paper sx={{ my: { xs: 1, md: 1 }, p: { xs: 1, md: 1 } }}>
                  <TourMaps handleOk={handleOk} />
                </Paper>
              </Paper>
            }
          />

          {/* Mapa */}
          <SnackAlert open={openrest} handleClose={other.handleClose} />
          <SnackSave oksave={oksave} open={Opensave} handleClose={other.handleClose} />
          <MapaGit
            zm={center.zoom}
            coordenateOpen={coordenateOpen}
            onClickCoordenate={onClickCoordenate}
            coord={center}
            handleClose={handleClose}
            marker={marker}
            toggleDraggable={toggleDraggable}
            draggable={draggable}
            map={map}
            setMap={setMap}
            rest={rest}
            cancel={cancel}
            openSnak={openSnak}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
          
        </>
      )}
    </>
  );
}
