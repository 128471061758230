import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import InputMask from "react-input-mask";


// ----------------------------------------------------------------------

TextFieldDocument.propTypes = {
  name: PropTypes.string,
};

export default function TextFieldDocument({ name, ...other }) {

  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (

        <InputMask
          {...field}
          mask={other.mask}
          value={field.value}
          onChange={field.onChange}
          maskChar=""
          disabled={other.DisabledButton}
          {...other}
        >
          {() =>
            <TextField
              {...field}
              fullWidth
              error={!!error}
              helperText={error?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {other.icon}
                  </InputAdornment>
                ),
              }}
              {...other}

            />}
        </InputMask>






      )}
    />


  );
}
