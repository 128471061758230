import { useState } from 'react';
// material
import { Stack, Button, Divider, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ProgressCircular from '../../components/progress/ProgressCircular';
import { useAuth } from './AuthProvider';

// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------
const registerCompany = process.env.REACT_APP_RegisterCompany;

export default function AuthTypeUser() {

  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const handleToggle = () => {
    setOpen(true)
    window.setTimeout(() => {
      navigate(registerCompany, { replace: true });
      setOpen(false)
    }, 2000);
  };

  return (
    <>
      <ProgressCircular open={open} />
      <Stack direction="row" spacing={2}>
        <Button onClick={handleToggle} fullWidth size="large" color="inherit" variant="outlined">
          <Iconify icon="icon-park-solid:chef-hat" color="#DF3E30" width={22} height={22} />
          <Typography variant="body2" sx={{ px: 2, color: 'text.secondary' }}>
            Restaurant
          </Typography>
        </Button>

        <Button fullWidth size="large" color="inherit" variant="outlined">
          <Iconify icon="carbon:restaurant-fine" color="#1877F2" width={22} height={22} />
          <Typography variant="body2" sx={{ px: 2, color: 'text.secondary' }}>
            Comenzar
          </Typography>
        </Button>
      </Stack>

      <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          OR
        </Typography>
      </Divider>
    </>
  );
}
