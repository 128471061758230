import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCookies } from 'react-cookie';

// @mui
import { Link, Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as EmailValidator from 'email-validator';
import { useAuth } from '../AuthProvider';
// components
import Iconify from '../../../components/Iconify';
import ProgressCircular from '../../../components/progress/ProgressCircular';
import { FormProvider, RHFTextField, RHFCheckbox, TransitionAlerts } from '../../../components/hook-form';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const auth = useAuth();

  const url = process.env.REACT_APP_API_AUTH;

  //   const navigate = useNavigate();
  const CONEC_API = url;
  //  const [cookies, setCookie] = useCookies(['name']);

  const [showPassword, setShowPassword] = useState(false);
  const [typeconect, setTypeconect] = useState([]);
  const [valueget, setValueget] = useState();
  const [Messenge, setMessenge] = useState('');
  const [StatusAlert, setStatusAlert] = useState(false);
  const [Severity, setSeverity] = useState('success');
  const [open, setOpen] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required('Suministre información de usuario registrado.'),
    // email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Se requiere una clave.'),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });
  // console.log(methods);

  const {
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = methods;

  const campodinac = watch('email');
  const passworddina = watch('password');

  // console.log(campodinac);
  window.setTimeout(() => {
    // validar si es telefono, email, o usuario
    const valid = EmailValidator.validate(campodinac);
    // const vall = prompt(values.email);
    const regex = /^[0-9]+$/;
    const validnro = regex.test(campodinac);

    if (valid) {
      setTypeconect('login/email');
    } else if (validnro) {
      setTypeconect('login/phone');
    } else {
      setTypeconect('login/username');
    }
  }, 3);

  useEffect(() => {
    switch (typeconect) {
      case 'login/email':
        setValueget({
          email: campodinac.toLowerCase(),
          password: passworddina,
        });
        break;
      case 'login/phone':
        setValueget({
          phone: campodinac,
          password: passworddina,
        });
        break;
      case 'login/username':
        setValueget({
          username: campodinac.toLowerCase(),
          password: passworddina,
        });
        break;
      default:
        setTypeconect('login/username');
    }
  }, [typeconect, campodinac, passworddina]);

  const onSubmit = async () => {
    try {
      const CONFIG_JSON = {
        method: 'POST',
        headers: {
          referrerPolicy: 'unsafe_url',
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'https://kartaqr.com',
          mode: 'same-origin',
        },
        body: JSON.stringify(valueget),
      };
      //
      const res = await fetch(`${CONEC_API}${typeconect}`, CONFIG_JSON);
      const RES_API = await res.json();
      console.log(RES_API);
      //
      if (res.status === 200) {
        auth.saveUser(RES_API);
      }
      //
      if (res.status === 400) {
        setStatusAlert(true);
        setSeverity('error');
        setMessenge(RES_API.message);
        window.setTimeout(() => {
          setStatusAlert(false);
        }, 3000);
      }

      if (res.status === 404) {
        setStatusAlert(true);
        setSeverity('error');
        setMessenge(RES_API.message);
        window.setTimeout(() => {
          setStatusAlert(false);
        }, 3000);
      }
      //
      if (res.status === 500) {
        setStatusAlert(true);
        setSeverity('error');
        setMessenge(RES_API.message);
        window.setTimeout(() => {
          setStatusAlert(false);
        }, 3000);
      }
      //
    } catch (error) {
      console.log(error);
      setStatusAlert(true);
      setSeverity('error');
      setMessenge('Error de conexión');
      window.setTimeout(() => {
        setStatusAlert(false);
      }, 3000);
    }
  };

  // if (auth.isAuthenticated) {
  //   console.log('redirigir');
  //   // document.location.href = 'https://kpanel.kartaqr.com';
  //  // document.location.href ='http://localhost:3001' ;
  //   // document.location.href = kpanel;
  // }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="email" label="Usuario/email/telefono" />
        <TransitionAlerts
          Messenge={Messenge}
          statusAlert={StatusAlert}
          severity={Severity}
          handleClose={() => setStatusAlert(false)}
        />
        <ProgressCircular open={open} />
        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label="Recordar" />
        <Link variant="subtitle2" underline="hover">
          ¿Olvidó su contraseña?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Login
      </LoadingButton>
    </FormProvider>
  );
}
