import * as Yup from 'yup';
import * as React from 'react';
import PropTypes from 'prop-types';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Typography, Link } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Checkbox from '@mui/material/Checkbox';

import {
  FormProvider,
  RHFTextField,
  SelectDocumenttype,
  TypeCompanySelect,
  PhoneInputnumber,
} from '../../../components/hook-form';

import ProgressCircular from '../../../components/progress/ProgressCircular';
// hook consulta
import useSelectConsult from '../../../hooks/consult/useConsultCombo';
import './RegisterForm.css';

const documenttypecompany = process.env.REACT_APP_documenttype_company;
const companytype = process.env.REACT_APP_companytype;

// ----------------------------------------------------------------------
RegisterForm.propTypes = {
  name: PropTypes.string,
};

export default function RegisterForm({ name, ...other }) {
  // tipo de documento empresas.
  const [typedc, error, loading] = useSelectConsult(documenttypecompany);

  //  tipo de comercio.
  const [typecm, erro, loading2] = useSelectConsult(companytype);

  const phoneRegExp = /24|28|29|27|25|26|21|41|42/;
  const code = /[\s-/^*@^a-zA-Z!-"#$%&/()+=?¡!¿'\\]/gi;

  const RegisterSchema = Yup.object().shape({
    companyName: Yup.string().required('Nombre de su empresa.'),
    companytype: Yup.object()
      .shape({ id: Yup.string().required('') })
      .required('campo obligatorio'),
    documenttype: Yup.object()
      .shape({ id: Yup.string().required('') })
      .required('campo obligatorio'),
    documentnumber: Yup.string()
      .min(9, 'Minimos 9 digitos')
      .max(12, 'Maximo de 12 digitos ')
      .transform((value) => value.replace(code, ''))
      .required('Para futuras facturacciones, es requerido.'),
    email: Yup.string()
      .email('Ingrese un Email valido ejemplo: Karta-bussines@karta.com')
      .required('El Email es Requerido.'),
    Phone: Yup.string()
      .min(12, 'El numero de telefono debe contener 12 digitos')
      .matches(phoneRegExp, 'Codigo no valido')
      .required('Ingrese numero de telefono.'),
  });

  const defaultValues = {
    companyName: '',
    companytype: { id: '' },
    documentnumber: '',
    documenttype: { id: '' },
    // country: { code: '', label: '', phone: '' },
    email: '',
    Phone: '',
    // password: '',

  };

  const methods = useForm({ resolver: yupResolver(RegisterSchema), defaultValues });

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    watch,
    register,
  } = methods;

  const aceptarterminos = watch('acpt');

  
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(other.onSubmit)}>
      <ProgressCircular open={loading && loading2 ? true : loading} />

      <Stack spacing={2}>
        <Stack
          direction={{
            // xs: 'column',
            xs: 'row',
            sm: 'row',
          }}
          spacing={2}
        >
          <RHFTextField name="companyName" label="Nombre de comercio" />
          {/* campo tipo de compañia */}
          <TypeCompanySelect
            name="companytype"
            apiconect={typecm}
            Optins={'Sin resultados'}
            label="Tipo de comercio"
            width="100%"
          />
        </Stack>
        <Stack direction={{ xs: 'row', sm: 'row' }} spacing={2}>
          {/* campo tipo de documento */}
          <SelectDocumenttype name="documenttype" apiconect={typedc} label="Tipo de documento" width="100%" />
          {/* campo de ingresar documento */}
          <RHFTextField name="documentnumber" type="number" label="Docuemto/Rif" />
        </Stack>
        {/* campo de EMAIL */}
        <RHFTextField name="email" label="Correo" />
        <PhoneInputnumber name="Phone" control={control} label="Telefono" width="100%" />

        <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
          Al registrarme, acepto de Karta&nbsp;
          <Link underline="always" color="text.primary" href="#">
            Términos de servicio
          </Link>
          {''} Y {''}
          <Link underline="always" color="text.primary" href="#">
            política de privacidad
          </Link>
          . <Checkbox color="primary" {...register('acpt')} />
        </Typography>

        <LoadingButton
          disabled={!aceptarterminos}
          name={name}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Register
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
