import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_SECRET_KEY_LOCALSTORAGE;

export default function dataDecrypt(value){
  try {
    const bytes = CryptoJS.AES.decrypt(value.CKIS, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    /// aqui van los logs
    console.log("🚀 ~ file:error:", error);
  }
};