import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
// sections
import { LoginForm } from '../sections/auth/login';
import AuthSocial from '../sections/auth/AuthSocial';

// ----------------------------------------------------------------------
const register = process.env.REACT_APP_register;
const registerCompany = process.env.REACT_APP_RegisterCompany;
const login = process.env.REACT_APP_LOGIN;
const imglogin = process.env.REACT_APP_illustration_login;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

/*
const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  backgroundImage: `url(/static/illustrations/illustration_login.jpg)`,
  backgroundRepeat: 'no-repeat',
  backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
  backgroundSize: 'cover',
  backgroundPosition: 'contain',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

*/

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          
          <Logo />

          {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              ¿No tienes una cuenta? {''}
              <Link variant="subtitle2" component={RouterLink} to={register}>
                Empieza
              </Link>
            </Typography>
          )}
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hola bienvenido de nuevo
            </Typography>
            <img src={imglogin} alt="login" />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Typography variant="h4" gutterBottom>
              Iniciar sesión en Karta Qr
            </Typography>

            <Typography sx={{ color: 'text.secondary', mb: 5 }}>Ingrese sus datos a continuación.</Typography>
            {
              //          <AuthSocial />
            }

            <LoginForm />

            {!smUp && (
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                ¿No tienes una cuenta?{' '}
                <Link variant="subtitle2" component={RouterLink} to={register}>
                  Empieza ahora{' '}
                </Link>
              </Typography>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
