import { useState, useEffect } from 'react';
import Geolocation from '@react-native-community/geolocation';

export default function useCoordss(datos, coord) {
  const [latitude, setlatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [errord, setErrord] = useState('');
  const [datas, setDat] = useState('');
  const [zom, setZoom] = useState('');
  const [statusloc, setStatusloc] = useState('');
  const [estatus, setStatus] = useState('');

  const center = {
    lat: latitude,
    lng: longitude,
    zoom: zom,
    erro: errord,
    formatted: statusloc,
    status: estatus,
  };
  const zoom = datos ? 16 : 15;

  // Coordenadas Automaticas.
  function success(pos) {
    setDat(pos);
  }

  window.setTimeout(() => {
    if (datas.coords) {
      if (coord) {
        setErrord(201);
        setlatitude(coord.lat);
        setLongitude(coord.lng);
        setZoom(coord.zoom);
      } else {
        setlatitude(datas.coords.latitude);
        setLongitude(datas.coords.longitude);
        setZoom(zoom);
        setErrord(200);
      }
    }
  });

  //  Si da error en obtener las coordenadas
  // Se estableceran coordenadas predeterminadas.
  function error(err) {
    setDat(err);
  }

  window.setTimeout(() => {
    if (datas.code) {
      if (coord.lat) {
        /// Coordenadas Manuales.

        setErrord(201);
        setlatitude(coord.lat);
        setLongitude(coord.lng);
        setZoom(coord.zoom);
      } else {
        setErrord(401); // Coordenadas QUEMADAS CCS VENEZUELA.
        setlatitude(10.4523);
        setLongitude(-66.9266);
        setZoom(9);
      }
    }
  });

  useEffect(() => {
    const fetchResource = async () => {
      try {
        Geolocation.getCurrentPosition(success, error);
      } catch (error) {
        setErrord(error);
      }
    };

    fetchResource();
  }, [datos]);

 /* useEffect(() => {
    const fetchDirections = async () => {
      try {
        const res = await fetch(
          `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=a08ab980938843789855fc7e05f161d3&no_annotations=1&language=es`
        );
        const data = await res.json();

        setStatus(200);
        setStatusloc(data.results[0]);
      } catch (error) {
        console.log(error);
      }
    };
    /*
        if (latitude) {
            fetchDirections()
        }

  }, [latitude, longitude, datos]);
*/
  /* useEffect(() => {

        const fetchDirections2 = async () => {
            try {
                const res = await fetch(`https://api.opencagedata.com/geocode/v1/json?q=${coord.address}&key=a08ab980938843789855fc7e05f161d3&no_annotations=1&language=es`)
                const data = await res.json()

                console.log(data)
                setStatus(200)
               
            } catch (error) {

                console.log(error)
            }

        }

        if (coord.address) {
            fetchDirections2()
        }

    }, [coord.address])
*/

  return center;
}
