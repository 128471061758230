import { useState, useEffect } from 'react';
// import url from '../../hooksApi'

export default function useSelectConsult(sqm) {
  // /root/.nvm/versions/node/v18.6.0/lib/node_modules/pm2/bin/pm2
  const url = process.env.REACT_APP_API_KARTA; // Conection Api
  const [typecm, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const get = url + sqm;

  const fetchResource = async () => {
    try {
      const res = await fetch(get);
      const data = await res.json();
      // #
      setData(data);
      setLoading(false);
    } catch (error) {
      setLoading(true);
      setError(error);
    }
  };

  useEffect(() => {
    fetchResource();
  }, []);

  return [typecm, error, loading];
}
