import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import OtpInput from 'react-otp-input';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import './stylesOTP.css';
import useResponsive from '../../hooks/useResponsive';
import { AlertsCinta, Contador } from '../hook-form';

ModalAlert.propTypes = {
  name: PropTypes.string,
};
export default function ModalAlert({ name, ...other }) {
  const Cookies = useResponsive('Cookies');
  const [Token, setCookies] = Cookies;
  const url = process.env.REACT_APP_API_KARTA; // Conection Api

  // Cookie para verificar si actualizo el correo.
  const cokieOTP = Token.cookieOTP;
  const emailUpdate = Token.C307.email;

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sendmail, setSendmail] = useState('Generar nuevo codigo');
  const [sucesssend, setSucesssend] = useState(false);
  const [sucesscolor, setSucesscolor] = useState('primary');
  const [activeCount, setActiveCount] = useState();
  const [disabledbtn, setDisabledbtn] = useState(true);
  const [timeOtp, setTimeOtp] = useState(true);
  const [msj, setMsj] = useState(false);

  /// contador TimeProgresivo
  const [hh, setHH] = useState(0);
  const [mm, setMM] = useState(0);
  const [ss, setSS] = useState(0);

  /// mensaje de Alert:
  const [Messenge, setMessenge] = useState();
  const [StatusAlert, setStatusAlert] = useState(false);
  const [Severity, setSeverity] = useState();
  // Bloqueo de modal por Update
  const [blockmodal, setblockmodal] = useState(false);

  window.setTimeout(() => {
    if (cokieOTP[0] !== emailUpdate && cokieOTP[1] === Token.C307.id) {
      setblockmodal(true);
      setActiveCount(timeOtp);
      setLoading(false);
      setDisabledbtn(false);
      setSendmail('Direccion de correo actualizado...');

      window.setTimeout(() => {
        setSucesssend(true);
        setMsj(true);
      }, 2000);
      setCookies('cookieOTP', Token.C307.email);
    }
  }, 10);

  /// Cuando se actualiza el Correo:
  window.setTimeout(() => {
    if (cokieOTP[0] !== emailUpdate && cokieOTP[1] === Token.C307.id) {
      setblockmodal(true);
      setActiveCount(timeOtp);
      setLoading(false);
      setDisabledbtn(false);
      setSendmail('Direccion de correo actualizado...');

      window.setTimeout(() => {
        setSucesssend(true);
        setMsj(true);
      }, 2000);
      setCookies('cookieOTP', Token.C307.email);
    }
  }, 10);

  const handleClick = async () => {
    setLoading(true);

    const valuePut = {};

    try {
      const CONFIG_JSON = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          mode: 'same-origin',
        },
        body: JSON.stringify(valuePut),
      };
      const res = await fetch(`${url}user/codeotp/generate/${Token.C307.id}`, CONFIG_JSON);
      const RES_API = await res.json();

      const otp = RES_API.timeotp;

      /// console.log(RES_API);

      if (res.status === 200) {
        setActiveCount(otp);
        setLoading(false);
        setDisabledbtn(false);
        setSendmail('Revisa tu correo');
        setTimeOtp(otp);
        window.setTimeout(() => {
          setSucesssend(true);
          setMsj(true);
        }, 2000);
      }

      if (res.status === 400) {
        
        
        setStatusAlert(true);
        setMessenge(`${RES_API.message } enviado tu correo.`);
        setSeverity('info');

        window.setTimeout(() => {
          setSucesssend(false);
          setSucesscolor('primary');
          setStatusAlert(false);
          setLoading(false);
          setDisabledbtn(true);
        }, 3000);
      }
      if (res.status === 500) {
        setStatusAlert(true);
        setMessenge('Espere... estamos buscando un codigo.');
        // setMessenge(RES_API.message, 'espere...');
        setSeverity('info');

        window.setTimeout(() => {
          setSucesssend(false);
          setSucesscolor('primary');
          setStatusAlert(false);
          setLoading(false);
          setDisabledbtn(true);
        }, 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /// timeprogresivo
  const hora = hh < 10 ? `0${hh}` : hh;
  const segundos = ss < 10 ? `0${ss}` : ss;
  const minutos = mm < 10 ? `0${mm}` : mm;
  const tiempo = `${hora}:${minutos}:${segundos}`;

  useEffect(() => {
    const sampleInterval = setInterval(() => {
      if (activeCount) {
        if (ss === 59) {
          setSS(0);
          setMM(mm + 1);
        } else {
          setSS(ss + 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(sampleInterval);
    };
  });

  if (activeCount === tiempo) {

    setblockmodal(false);
    setMsj(false);
    setDisabledbtn(true);
    setSucesssend(false);
    setSucesscolor('primary');
    setSendmail('Generar un codigo');
    setActiveCount();
    setSS(0);
    setMM(0);
  }

  useEffect(() => {
    setOpen(other.open);
  }, [other]);

  return (
    <div>
      <Dialog
        onClose={other.handleClose}
        name={name}
        open={blockmodal ? true : open}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
          Introduce el codigo enviado a tu correo.
        </Typography>

        <DialogContent>
          <div className="margin-top--small">
            <OtpInput
              inputStyle="inputStyle"
              numInputs={6}
              isDisabled={other.isDisabled}
              hasErrored={other.hasErrored}
              errorStyle="error"
              onChange={other.handleChangeOTP}
              separator={<span>-</span>}
              isInputNum="true"
              // isInputSecure={isInputSecure}
              shouldAutoFocus
              value={other.valueOTP}
              placeholder="123456"
            />
            <AlertsCinta
              Messenge={other.Messenge}
              statusAlert={other.StatusAlert}
              severity={other.Severity}
              // handleClose={() => setStatusAlert(false)}
            />
            <AlertsCinta Messenge={Messenge} statusAlert={StatusAlert} severity={Severity} />
            <Typography variant="body2" align="justify" sx={{ color: 'text.secondary', mt: 3 }}>
              Si aun no has recibido un codigo de parte de Karta Qr. Dar click al boton.
            </Typography>
            <LoadingButton
              disabled={!disabledbtn}
              sx={{ mt: 2, mb: 2 }}
              fullWidth
              onClick={handleClick}
              startIcon={sucesssend ? <AccessTimeIcon /> : ''}
              color={sucesscolor}
              loading={loading}
              variant="contained"
            >
              {msj ? <Contador min={activeCount} /> : sendmail}
            </LoadingButton>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
