import React, { useRef, useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
// import Paper from '@mui/material/Paper';
import FormHelperText from '@mui/material/FormHelperText';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import 'leaflet/dist/leaflet.css';
import './react-leaflet.css';
import maplibregl from 'maplibre-gl';
import { GeocodingControl } from '@maptiler/geocoding-control/react';
// import List from '@mui/material/List';
import * as maptilersdk from '@maptiler/sdk';
// import Geolocation from '@react-native-community/geolocation';
import { createMapLibreGlMapController } from '@maptiler/geocoding-control/maplibregl-controller';
import ProgressCircular from '../progress/ProgressCircular';
import useResponsive from '../../hooks/useResponsive';
import '@maptiler/geocoding-control/style.css';
import 'maplibre-gl/dist/maplibre-gl.css';
import { TransitionAlerts } from '../hook-form';
import './map.css';

Mapp.propTypes = {
  name: PropTypes.string,
};

export default function Mapp({ name, ...other }) {
  const url = process.env.REACT_APP_API_KARTA; // Conection Api
  // const urlmap = process.env.REACT_APP_KEY_LOCATION_URL; // Conection Api location.
  const urlmap = process.env.REACT_APP_KEY_LOCATION_URL2; // Conection Api location.
  const key = 'SqP0f0G2QPiITQefjwIA'; // Conection Api location.
  // const key = 'SqP0f0G2QPiITQefjwIA'; // Conection Api location.
  // const key = process.env.REACT_APP_KEY_LOCATION_KARTAQR; // Conection Api location.
  // const key = process.env.REACT_APP_KEY_LOCATION_FREE; // Conection Api location.

  const Cookies = useResponsive('Cookies');
  const [Token, setCookies] = Cookies;

  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng] = useState(10.471416);
  const [lat] = useState(66.876779);
  const [zoo] = useState(2);
  // const [API_KEY] = useState('NGn4HfElro9TK6pXPbrR');
  const [mapController, setMapController] = useState();
  const [mark, setMark] = useState({});
  const [markData, setMarkData] = useState('');
  const [message, setMesseger] = useState('Cargando inf...');
  const [dataMapa, setDataMapa] = useState([]);
  const [openloction, setOpenloction] = useState(false);
  const [onClickCoordenatetrue, setOnClickCoordenatetrue] = useState(false);
  const [parroquia, setParroquia] = useState();
  const [municipality, setMunicipality] = useState();
  const [region, setRegion] = useState();
  const [openProgress, setOpenProgress] = useState(false);
  const [Messenge, setMessenge] = useState('');
  const [StatusAlert, setStatusAlert] = useState(false);
  const [Severity, setSeverity] = useState('success');
  /// const [Suma, setSuma] = useState();

  maptilersdk.config.apiKey = key;
  const urlconect = `https://api.maptiler.com/maps/basic-v2/style.json?key=${key}`;
  // const urlconect = `${urlmap}${key}`;

  useEffect(() => {
    if (map.current) return; // stops map from intializing more than once

    map.current = new maplibregl.Map({
      container: mapContainer.current,
      //  style: urlconect,
      // center: [-74.0066, 40.7135],
      center: [other.coord.lng, other.coord.lat],
      zoom: 15.5,
      pitch: 45,
      bearing: -17.6,
      // container: 'map',
      antialias: true,
      style: {
        version: 8,
        name: 'Blank',
        center: [0, 0],
        zoom: 0,
        sources: {
          'raster-tiles': {
            type: 'raster',
            tiles: ['https://tile.openstreetmap.org/{z}/{x}/{y}.png'],
            tileSize: 256,
            minzoom: 0,
            maxzoom: 19,
          },
        },
        layers: [
          {
            id: 'background',
            type: 'background',
            paint: {
              'background-color': '#e0dfdf',
            },
          },
          {
            id: 'simple-tiles',
            type: 'raster',
            source: 'raster-tiles',
          },
        ],
        id: 'blank',
      },

      // center: [0, 0],
      /*
            center: [other.coord.lng, other.coord.lat],
            // center: [lng, lat],
            zoom: other.zm,
            // zoom: zoo,
            pitch: 10,
            bearing: 10,
            antialias: true,
            terrainControl: true,
            terrain: true,
            touchZoomRotate: true, 
            */
    });

    // data from OpenStreetMap.
    map.current.on('load', () => {
      // Insert the layer beneath any symbol layer.
      const layers = map.current.getStyle().layers;

      let labelLayerId;
      for (let i = 0; i < layers.length; i += 1) {
        if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
          labelLayerId = layers[i].id;
          break;
        }
      }

      map.current.addSource('openmaptiles', {
        url: `https://api.maptiler.com/tiles/v3/tiles.json?key=SqP0f0G2QPiITQefjwIA`,
        type: 'vector',
      });

      map.current.addLayer(
        {
          id: '3d-buildings',
          source: 'openmaptiles',
          'source-layer': 'building',
          type: 'fill-extrusion',
          minzoom: 15,
          paint: {
            'fill-extrusion-color': [
              'interpolate',
              ['linear'],
              ['get', 'render_height'],
              0,
              'lightgray',
              200,
              'royalblue',
              400,
              'lightblue',
            ],
            'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 15, 0, 16, ['get', 'render_height']],
            'fill-extrusion-base': ['case', ['>=', ['get', 'zoom'], 16], ['get', 'render_min_height'], 0],
          },
        },
        labelLayerId
      );
    });

    const pointer = new maplibregl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation: true,
      showUserLocation: false,
    });

    map.current.addControl(pointer, 'bottom-right');

    map.current.addControl(new maplibregl.NavigationControl(), 'bottom-right');

    // imagenge del marcador o puntero
    const el = document.createElement('div');
    el.id = 'marker';
    const marker = new maplibregl.Marker({ element: el, color: '#FF0000', draggable: true });

    // Al hacer click aparece el puntero y centra a la vez.
    map.current.on('click', async (e) => {
      if (mark) {
        marker.remove();
      }
      const res = await maptilersdk.geocoding.reverse([e.lngLat.lng, e.lngLat.lat]);
      setMark(e.lngLat);
      setMarkData(res);

      marker.setLngLat(e.lngLat).addTo(map.current);

      map.current.flyTo({
        center: e.lngLat,
      });
    });

    /// arrastra punto y cambia la direccion y centra.
    marker.on('dragend', onDragEnd);

    function onDragEnd() {
      const lngLat = marker.getLngLat();

      const onchange = async () => {
        const res = await maptilersdk.geocoding.reverse([lngLat.lng, lngLat.lat]);
        setMarkData(res);
      };
      onchange();
      setMark(lngLat);
      map.current.flyTo({
        center: lngLat,
      });
    }

    setMapController(createMapLibreGlMapController(map.current, maplibregl));
  }, [urlconect, lng, lat, zoo, mark, other.zm, other.coord.lng, other.coord.lat]);

  useEffect(() => {
    const datMesseger = markData.features;
    const PushAddress = [];

    // console.log(datMesseger);  // datos del mapa!!!

    if (Array.isArray(datMesseger) && datMesseger[0]) {
      const lenguaje = datMesseger.filter((datepoint) => datepoint.language);

      for (let i = 0; i < datMesseger.length; i += 1) {
        if (datMesseger[i].place_type[0] === 'joint_municipality') {
          setMunicipality(datMesseger[i].text);
        }
        if (datMesseger[i].place_type[0] === 'municipality') {
          setParroquia(datMesseger[i].text);
        }
        if (datMesseger[i].place_type[0] === 'region') {
          // console.log(datMesseger[i].text);
          setRegion(datMesseger[i].text.replace(' State', ''));
        }
        PushAddress.push(datMesseger[i].text);
        // console.log(PushAddress);

        // console.log(PushAddress.toString());

        if (lenguaje[0].language === 'es') {
          setMesseger(PushAddress.toString());
          setDataMapa(datMesseger[i]);
          setOpenloction(true);
        } else {
          setDataMapa(datMesseger[i]);
          setMesseger(PushAddress.toString());
          setOpenloction(true);
        }
      }
    } else {
      setOpenloction(false);
    }
  }, [message, markData.features]);

  const onSubmit = async () => {
    const val = {
      iduser: Token.C307.id,
      isocountry: dataMapa.properties.country_code,
      stateprovince: region.replace('Estado ', ''),
      municipality: municipality.replace('Municipio ', ''),
      parish: parroquia.replace('Parroquia ', ''),
      coordinates: `${mark.lat},${mark.lng}`,
      address: message,
    };
    console.log(val);
    try {
      setOnClickCoordenatetrue(true);

      const CONFIG_JSON = {
        method: 'POST',
        headers: {
          referrerPolicy: 'unsafe_url',
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          mode: 'same-origin',
        },
        body: JSON.stringify(val),
      };

      const res = await fetch(`${url}company/register/location`, CONFIG_JSON);
      const RES_API = await res.json();

      if (res.status !== 200) {
        setStatusAlert(true);
        setSeverity('error');
        // setMessenge(RES_API.message);
        setMessenge('Disculpe, intente de nuevo.');

        window.setTimeout(() => {
          setStatusAlert(false);
          setOnClickCoordenatetrue(false);
        }, 4000);
      }
      if (res.status === 200) {
        const val = {
          id: Token.C307.id,
          idstatus: RES_API.idstatus,
        };
        setOpenProgress(true);
        setCookies('C307', val);
        window.setTimeout(() => {
          other.setActiveStep(other.activeStep + 1);
        }, 1000);
      }
    } catch (error) {
      //       console.log(error);

      if (error) {
        setOnClickCoordenatetrue(false);
      }
    }
  };

  return (
    <div className="map-wrap">
      <div className="geocoding">
        <ProgressCircular open={openProgress} />

        <TransitionAlerts
          Messenge={Messenge}
          statusAlert={StatusAlert}
          severity={Severity}
          handleClose={() => setStatusAlert(false)}
        />

        <GeocodingControl
          // proximity={[datas.coords.latitude, datas.coords.longitude]} // Busca lo aproximado a tu ubicacion.
          country={['VE']}
          placeholder="Buscar"
          language={'es'}
          focus="false"
          noResultsMessage={'Opps, Parece que no podemos encontrar esa localidad. Intenta nuevamente.'}
          apiKey={key}
          mapController={mapController}
        />
      </div>
      <div ref={mapContainer} className="map" />
      <Snackbar
        sx={{
          my: { xs: 5, md: 5 },
          p: { xs: 5, md: 5 },
          '& .MuiSnackbarContent-root': { backgroundColor: 'snakbar' },
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openloction}
        message={
          <>
            {' '}
            <FormHelperText error variant="filled">
              <Typography component={'span'} variant={'body2'}>
                {' '}
                {message}
              </Typography>
              <Typography component={'span'} variant={'body2'}>
                {' '}
                {mark.lat}, {mark.lng}
              </Typography>
            </FormHelperText>
            <FormHelperText margin="dense" variant="filled">
              <LoadingButton
                // sx={{ mt: 2, mb: 2 }}
                name={name}
                // fullWidth
                size="large"
                type="submit"
                variant="contained"
                loadingPosition="center"
                loading={onClickCoordenatetrue}
                onClick={onSubmit}
              >
                Guardar dirección
              </LoadingButton>
            </FormHelperText>
          </>
        }
      />
    </div>
  );
}
